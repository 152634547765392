import { IDrawer, initialDrawer, initialNotifications, INotifications } from "./model";
import { IActionBase } from "../IActionBase";
import { UPDATE_DRAWER, UPDATE_NOTIFICATIONS } from "./action";

export function drawerReducer(
    state: IDrawer = initialDrawer,
    action: IActionBase): IDrawer {

    switch (action.type) {
        case UPDATE_DRAWER:
            return { ...state, open: action.open };
        default:
            return state;
    }
}

export function INotificationsReducer(
    state: INotifications = initialNotifications,
    action: IActionBase): INotifications {

    switch (action.type) {
        case UPDATE_NOTIFICATIONS:
            return { ...state, quantity: action.quantity };
        default:
            return state;
    }
}
