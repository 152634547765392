import React from "react";
import {
    Drawer,
    Grid,
    makeStyles,
    SwipeableDrawer,
} from '@material-ui/core';

import { SideDrawerProps } from "./sideDrawerProps";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../store/rootModel";
import { drawerWidth } from "../../../global";
import { updateDrawer } from "../../../store/SideDrawer/action";
import NavLinkList from "../_components/navLinkList";

/** css in js(ts)  */
import clsx from "clsx";
const useStyle = makeStyles((theme) => ({
    swipeableDrawer: {
        border: "none",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        zIndex: 1,
        paddingTop: "81px",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            overflow: "auto"
        },
        [theme.breakpoints.down("sm")]: {
        }
    },
    drawer: {
        width: "50vw",
        background: "#019fe8",
        minHeight: "100vh",
        overflowX: "hidden",
        [theme.breakpoints.down("md")]: {
            width: "80vw",
        },
    }
}));

const Mobile: React.FCX<SideDrawerProps> = (props) => {
    const classes = useStyle();
    const drawer = useSelector((state: IRootState) => state.drawer);
    const dispatch = useDispatch<any>();//reducerはこれで呼び出す

    function onToggleDrawar() {
        dispatch(updateDrawer(!drawer.open));
    }
    return (<div>
        <SwipeableDrawer
            anchor="right"
            onOpen={() => onToggleDrawar()}
            onClose={() => onToggleDrawar()}
            open={drawer.open}
            className={classes.swipeableDrawer}
        >
            <div className={classes.drawer}>
                {/* メニューリストはスマフォ/PCで共通コンポーネント化  */}
                <NavLinkList mobile={true} />
            </div>
        </SwipeableDrawer>
    </div>);
};

export default Mobile;