import React from 'react';
import { Route } from 'react-router';
import { Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import history from './history';
import {
    makeStyles,
    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import { isIE } from 'react-device-detect';
import { useSelector } from 'react-redux';

/* 業務固有のimport */
import { IRootState } from './store/rootModel';
import NotSupport from './views/specialCase/notSupport';
import NotFound from './views/specialCase/notFound';
import RedirectView from './views/redirect';
import Layout from './views/common/lyaout';

/** css in js(ts)  */
const cssInCode = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

type Props = {
    path:string;
}
const DefaultLayoutRoot: React.FCX<Props> = (props) => {
    const { children, path, ...rest } = props;
    return (
        <Route exact path={path}>
            <Layout>
                {children}
            </Layout>
        </Route>        
    );
}
const AppRoute: React.FC = () => {
    const css = cssInCode();
    const overlay = useSelector((x: IRootState) => x.overlay);

    return (
            <ConnectedRouter history={history}>
                {/** react のルーティングで、ここにパス事のコンポーネントを宣言する */}
                <Switch>
                    <Route exact path='/'>
                        <></>
                    </Route>
                    <Route exact path='/redirect'>
                        <RedirectView />
                    </Route>
                    { /* URL違う場合 */}
                    <Route  path='*'>
                        <NotFound />
                    </Route>
                </Switch>
            </ConnectedRouter>
    );
};

export default AppRoute;