import React, { useState } from "react";
import useMedia from 'use-media';
import {
    makeStyles,
    Grid,
    AppBar,
    Toolbar,
    Theme,
    Badge,
    Button,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import Logo from "../../../components/Logo";
import { TitleNavigationProps } from "./titleNavigationProps";
import { appbarHeight } from "../../../global";

/** css in js(ts)  */
import clsx from "clsx";
const useStyle = makeStyles((theme: Theme) => ({
    root: {
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.background.paper,
        WebkitTransition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        top: 0,
        height: appbarHeight,
        display: "flex",
        justifyContent: "center"
    },
    SettingIcon: {
        padding: "0 10px"
    },
    NoticeIcon: {
        padding: "0 10px"
    },
    btn: {
        width: "127px",
        height: "48px",
    }
}));
const PC: React.FCX<TitleNavigationProps> = (props) => {
    const classes = useStyle();
    const isWide = useMedia({ minWidth: 1000 });
    const [value, setValue] = useState("");

    return (
        <div className={clsx(classes.root)}>
            <AppBar
                elevation={0 /* タイトルバーのshadowスタイルを消す */}
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <Grid container>
                        <Grid item xs={8}>
                            <Grid container >
                                <Grid item>
                                    <Logo />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} >
                            <Grid container justify="flex-end">
                                <Grid item className={classes.NoticeIcon}>
                                    <Button
                                        variant="outlined"
                                        className={classes.btn}
                                        startIcon={<Badge badgeContent={3} color="error">
                                            <NotificationsIcon />
                                        </Badge>}
                                    >
                                        お知らせ
                                    </Button>
                                </Grid>

                                <Grid item className={classes.SettingIcon}>
                                    <Button
                                        variant="outlined"
                                        className={classes.btn}
                                        startIcon={<SettingsIcon />}
                                    >
                                        各種設定
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar >
        </div >
    );
};

export default PC;