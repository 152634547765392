import React from "react";
import {
    makeStyles,
    Grid,
    AppBar,
    Toolbar,
    BottomNavigationAction,
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../store/rootModel";
import { appbarMobileHeight } from "../../../global";
import { TitleNavigationProps } from "./titleNavigationProps";
import MenuIcon from '@material-ui/icons/Menu';
import { updateDrawer } from "../../../store/SideDrawer/action";
import Logo from "../../../components/Logo";

/** css in js(ts)  */
import clsx from "clsx";
const useStyle = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "inline",
        top: "20px",
        right: "20px",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.background.paper,
        WebkitTransition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        top: 0,
        height: appbarMobileHeight,
    },
    toolBar: {
        paddingRight: 0
    },
    logoContainer: {
        WebkitFlexGrow: 1,
        flexGrow: 1,
        display: "flex",
        WebkitAlignItems: "center",
        alignItems: "center",
    },
    humburgerContainer: {
        background: "#019fe8",
        color: "white",
        "& .MuiBottomNavigationAction-label": {
            color: "white",
            opacity: "1 !important"
        }
    },
    humburgerAction: {
        height: appbarMobileHeight,
    },
    humburgerIcon: {
        width: "35px",
        zIndex: 2,
        "& span": {
            color: "white",
            opacity: 1
        }
    }
}));

const Mobile: React.FCX<TitleNavigationProps> = (props) => {
    const classes = useStyle();
    const title = useSelector((state: IRootState) => state.title);
    const drawer = useSelector((state: IRootState) => state.drawer);
    const dispatch = useDispatch<any>();//reducerはこれで呼び出す

    function onToggleDrawar() {
        dispatch(updateDrawer(!drawer.open));
    }
    return (
        <div className={clsx(classes.root)}>
            <AppBar
                elevation={0 /* タイトルバーのshadowスタイルを消す */}
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar className={classes.toolBar}>
                    <Grid container>
                        <Grid item className={classes.logoContainer}>
                            <Logo />
                        </Grid>
                        <Grid item className={classes.humburgerContainer}>
                            <BottomNavigationAction
                                className={classes.humburgerAction}
                                label="メニュー"
                                icon={<MenuIcon height="50px" />}
                                onClick={() => onToggleDrawar()}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div >
    );
};

export default Mobile;