import { IDrawer } from "./model";
import { INotifications } from "./model";

import { IActionBase } from "../IActionBase";

export const UPDATE_DRAWER = "UPDATE_DRAWER";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";


interface IDrawerType extends IDrawer, IActionBase { }
export function updateDrawer(open: boolean): IDrawerType {
    return {
        type: UPDATE_DRAWER,
        open: open,
    };
}

interface INotificationsType extends INotifications, IActionBase { }
export function updateNotifications(quantity: number): INotificationsType {
    return {
        type: UPDATE_NOTIFICATIONS,
        quantity: quantity,
    };
}
