import { IAccount } from "./model";
import { IActionBase } from "../IActionBase";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
interface IAccountType extends IAccount, IActionBase { }
export function UpdateAccount(
    account: IAccount
): IAccountType {
    return { type: UPDATE_ACCOUNT, ...account };
}
