export interface IAccount {
    auth: boolean;
    displayName?: string;
}


export const initialAccount: IAccount = {
    auth: false,
    displayName: "西部 太郎",
}
