import React, { Dispatch, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector, useDispatch } from "react-redux";
import Layout from './views/common/lyaout';
import AppRoute from './AppRoute';
import { IRootState } from './store/rootModel';
import { SnackbarProvider } from 'notistack';
import "./core/extensions/EnumerableExtension";
import "./core/extensions/ObjectExtension";
import "./core/extensions/SnackbarExtension";
import "./core/extensions/StringExttension";


import {
    makeStyles,
    CircularProgress,
    createMuiTheme,
    CssBaseline,
} from "@material-ui/core";

/** css in js(ts)  */
const cssInCode = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    error: {
        backgroundColor: "#f5c7c0",
        color: "#5d5b5b",
    },
}));


const App: React.FC = () => {
    const css = cssInCode();
    const dispatch: Dispatch<any> = useDispatch();
    const overlay = useSelector((x: IRootState) => x.overlay);
    const customTheme = useSelector((x: IRootState) => x.customTheme);
    const customPallete = useSelector((x: IRootState) => x.customPalette);
    const overrideTheme = createMuiTheme({
        typography: {
            "fontFamily": `"Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif"`,
        },
        palette: {
            type: customTheme.mode,
            primary: customPallete.primary,
            secondary: customPallete.secondary,
            background: customPallete.background ?? (customTheme.mode === "dark" ?
                { default: "#303030", paper: "#424242" } : { default: "#eaf8ff", paper: "#fff" }
            ),
            text: {
                primary: "#0D1844",
                secondary: ""
            },
        },
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    color: customTheme.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.87)",
                    backgroundColor: customTheme.mode === "dark" ? "#303030" : "#F2F5FA"
                }
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: "rgba(0,0,0,0.6)",
                }
            },
            MuiDrawer: {
                paper: {
                    background: "#019fe8"
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            }
        },
    });

    return (
        <ThemeProvider theme={overrideTheme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={5}
                classes={{
                    variantError: css.error,
                }}
            >
                <div id="wrapper">
                    <AppRoute />
                </div>
                <Backdrop className={css.backdrop} open={overlay.openWaitingSite} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
export default App;
