import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';

import { pcCopylight } from "../../global";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
    img: {
        height: 45,
        [theme.breakpoints.down("md")]: {
            height: 30,
        },
    },
}));
const Logo: React.FCX<any> = (props) => {
    const classes = useStyle();
    return (<div>
        <img className={classes.img} src="/logo.png"></img>
    </div>);
};

export default Logo;
