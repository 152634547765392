import React from "react";
import {
    Hidden
} from '@material-ui/core';

import PC from "./pc";
import Mobile from "./mobile";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    root: {
    }
}));

export type TitlebarProps = {
}

const Titlebar: React.FC = () => {
    const classes = useStyle();

    return (<>
        { /* smより小さくなった場合に非表示 ≒　PCサイズの場合 */}
        <Hidden smDown implementation="css">
            <PC
            />
        </Hidden>
        { /* mdより大きくなった場合に非表示 ≒　スマフォサイズの場合 */}
        <Hidden mdUp implementation="css">
            <Mobile
            />
        </Hidden>
    </>)
};

export default Titlebar;