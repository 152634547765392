import { makeStyles } from "@material-ui/core";
import React, { Dispatch } from "react";
import { useLifecycle } from "../../hooks/useLifecycle";
const cssInCode = makeStyles(theme => ({
    root: {
        width:"100vw",
        height:"1000vh"
    },
}));
type Props = {

}
const RedirectView: React.FCX<Props> = () => {
    const classes = cssInCode();
    const [onInitialized, _] = useLifecycle();
    onInitialized(async () => {
        const btn = document.getElementById("__btn");
        btn?.click();
    });
    return (<>
            <form
                className={classes.root}
                method="GET"
                hidden
                action={`/redirect.html`}
            >
                <input id="__btn" type="submit" />
            </form>    
    </>);
}

export default RedirectView;