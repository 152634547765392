export interface IDrawer {
    open: boolean;
}
export const initialDrawer: IDrawer = {
    open: false,
}
export interface INotifications {
    quantity: number;
}
export const initialNotifications: INotifications = {
    quantity: 5,
}
