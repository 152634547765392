import { PaletteColor, TypeBackground } from "@material-ui/core/styles/createPalette";
export type ThemeMode = "dark" | "light";
export interface ITheme {
    mode: ThemeMode;
}
export const initialTheme: ITheme = {
    mode: "light",
};

export interface IPalette {
    primary: PaletteColor;
    secondary: PaletteColor;
    appBar: string;
    background?: TypeBackground;
}
export const initialPalette: IPalette = {
    appBar: "white" /* "#303030" */,
    background: {
        default: "#eaf8ff",
        paper: "#fff"
    },
    primary: {
        light: "#7986cb",
        main: "#303f9f",
        dark: "#172171",
        contrastText: "#fff"
    },
    secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff"
    },
};
