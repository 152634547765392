import React from "react";
import useMedia from 'use-media';
import {
    Badge,
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

/** icons  */
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';

import { IRootState } from "../../../store/rootModel";

/** css in js(ts)  */
import clsx from "clsx";
const useStyle = makeStyles((theme: Theme) => ({
    root: {
        WebkitFlexGrow: 1,
        flexGrow: 1,
        paddingTop: 0
    },
    linkStyle: {
        color: theme.palette.text.primary,
        background: "transparent",
    },
    listItem: {
        height: "80px",
        [theme.breakpoints.down("md")]: {
            height: "60px",
        },
    },
    listItemIcon: {
        color: "white",
        minWidth: "35px",
        paddingLeft: theme.spacing(1)
    },
    unActiveListItem: {
        color: "white",
    },
    activeListItem: {
        color: "white",
        backgroundColor: "#077bb0 !important",
    },
    unActiveBorder: {
        opacity: 0,
        marginLeft: "-17px",
        marginRight: "10px"
    },
    center: {
        display: "flex",
        WebkitJustifyContent: "center",
        justifyContent: "center",
        WebkitAlignItems: "center",
        alignItems: "center"
    },
    divider: {
        background: "white",
        opacity: 0.3
    },
    activeBorder: {
        borderLeft: "4px solid white",
        opacity: 1,
        height: "60px",
        marginLeft: "-17px",
        marginRight: "20px"
    },
    icon: {
        fontSize: "1rem",
        "&>path": {
            fill: "white"
        }
    }
}));

type NavLinkListProps = {
    mobile?: boolean;
}
const NavLinkList: React.FCX<NavLinkListProps> = (props: NavLinkListProps) => {
    const classes = useStyle();
    const drawer = useSelector((state: IRootState) => state.drawer);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    function NavLink(props) {
        const { children, to, title, icon, index, ...rest } = props;
        return (
            <Link to={to}
                className={classes.linkStyle}
                style={{ textDecoration: 'none' }}>
                <ListItem button
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index)}
                    className={clsx(classes.listItem, classes.unActiveListItem, selectedIndex === index && classes.activeListItem)}
                >
                    <Box borderLeft={1} className={clsx(classes.unActiveBorder, selectedIndex === index && classes.activeBorder)} />
                    <ListItemIcon className={clsx(classes.listItemIcon)} >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} />
                    <ListItemIcon className={clsx(classes.center)} >
                        <ArrowForwardIosIcon className={classes.icon} />
                    </ListItemIcon>
                </ListItem>
                <Divider className={classes.divider} />
            </Link>
        );
    }

    function MobileMenu() {
        if (!props.mobile) {
            return (<></>);
        }
        return (<>
            <NavLink to="/" title="お知らせ" icon={
                <Badge badgeContent={3} color="error">
                    <NotificationsIcon fill={"white"} width="20" height="17" />
                </Badge>} index={100}
            />
            <NavLink to="/" title="各種設定" icon={<SettingsIcon fill={"white"} width="20" height="17" />} index={101} />
        </>);
    }

    return (<>
        <List component="nav"
            className={clsx(classes.root)}
        >
            <NavLink to="/" title="ホーム" icon={<HomeIcon fill={"white"} width="20" height="17" />} index={1} />
            <NavLink to="/gas" title="ガス使用量" icon={<WhatshotIcon fill={"white"} width="20" height="17" />} index={2} />
            <NavLink to="/electorical" title="電気使用量" icon={<FlashOnIcon fill={"white"} width="20" height="17" />} index={3} />
            <NavLink to="/cost" title="請求情報" icon={<AttachMoneyIcon fill={"white"} width="20" height="17" />} index={4} />
            <NavLink to="/account" title="契約照会" icon={<RecentActorsIcon fill={"white"} width="20" height="17" />} index={5} />
            <MobileMenu />
        </List>
    </>)
};

export default NavLinkList;